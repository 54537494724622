





































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem1LCELN1Argumentation1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        argumentation1Answer: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: 'Fundamental Skills Guiding Question: What is the most precise piece of glassware, and could you provide an explanation for its precision?',
          inputName: 'argumentation1Answer',
        },
      ],
    };
  },
});
